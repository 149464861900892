var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-row',[_c('b-col',{staticClass:"text-center px-0 py-2",attrs:{"cols":"12","md":"1"}},[_c('b-form-checkbox',{staticClass:"w-100 clsBtnFreight",class:(_vm.readonly || !_vm.cotation.quote_number || !_vm.cotation.cost || !_vm.cotation.carrier) ? 'disabled' : '',attrs:{"name":"check-button","button":"","size":"sm","button-variant":_vm.cotation.accepted ? 'success' : 'outline-info',"disabled":_vm.readonly || !_vm.cotation.quote_number || !_vm.cotation.cost || !_vm.cotation.carrier},model:{value:(_vm.cotation.accepted),callback:function ($$v) {_vm.$set(_vm.cotation, "accepted", $$v)},expression:"cotation.accepted"}},[(_vm.cotation.accepted)?_c('span',[_vm._v(" Contratado ")]):_c('span',[_vm._v(" Em Cotação ")])])],1),_c('b-col',{staticClass:"d-flex px-50",attrs:{"cols":"12","md":"3"}},[_c('b-row',[_c('b-col',{staticClass:"pr-50",attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"name":"quote_number","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Número da Cotação","label-for":"quote_number"}},[_c('b-form-input',{attrs:{"id":"quote_number","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"type":"number","placeholder":_vm.readonly ? '' : '0000',"readonly":_vm.readonly},model:{value:(_vm.cotation.quote_number),callback:function ($$v) {_vm.$set(_vm.cotation, "quote_number", $$v)},expression:"cotation.quote_number"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{staticClass:"pl-50",attrs:{"cols":"12","md":"7"}},[_c('carrier-select',{attrs:{"id":"carrier","readonly":_vm.readonly,"required-field":true,"truncate-field":17},on:{"ready":function($event){return _vm.syncLoad.ready('carrier')}},model:{value:(_vm.cotation.carrier_set),callback:function ($$v) {_vm.$set(_vm.cotation, "carrier_set", $$v)},expression:"cotation.carrier_set"}})],1)],1)],1),_c('b-col',{staticClass:"px-50",attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"phone_number","rules":{
            required: false,
            min: 14,
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Telefone","label-for":"phone_number"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMask()),expression:"getMask()"}],attrs:{"id":"phone_number","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"placeholder":_vm.readonly ? '' : '(99) 99999-9999'},model:{value:(_vm.cotation.phone_number),callback:function ($$v) {_vm.$set(_vm.cotation, "phone_number", $$v)},expression:"cotation.phone_number"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{staticClass:"px-50",attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"email","rules":{
            required: false,
            email: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"E-mail","label-for":"e-mail"}},[_c('b-form-input',{attrs:{"id":"e-mail","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"trim":"","placeholder":_vm.readonly ? '' : 'exemplo@syslic.com.br'},model:{value:(_vm.cotation.email),callback:function ($$v) {_vm.$set(_vm.cotation, "email", $$v)},expression:"cotation.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1),_c('b-col',{staticClass:"px-50",attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"date_cotation","rules":{
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data da Cotação","label-for":"date_cotation"}},[_c('b-form-datepicker',{class:errors.length > 0 ? 'form-control is-invalid' : 'form-control',attrs:{"required":true,"locale":"pt-BR","size":"md","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"label-no-date-selected":"Nenhuma data foi selecionada","disabled":_vm.readonly,"selected-variant":"primary","placeholder":"Selecione","label-help":"Use o teclado ou o mouse para navegar pelas datas do calendário","reset-button":"","reset-button-variant":"info","label-reset-button":"Limpar"},model:{value:(_vm.cotation.date_cotation),callback:function ($$v) {_vm.$set(_vm.cotation, "date_cotation", $$v)},expression:"cotation.date_cotation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"px-50",attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Valor do Frete","label-for":"freight_value"}},[_c('cleave-customized',{attrs:{"option":_vm.options.price,"places":2,"required-field":true,"readonly":_vm.readonly,"field-name":'freight_value'},model:{value:(_vm.cotation.cost),callback:function ($$v) {_vm.$set(_vm.cotation, "cost", $$v)},expression:"cotation.cost"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }