<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div>
      <history-list
        :history-list="historyList"
        :labels="labelList"
      />
    </div>
  </div>
</template>
<script>
import {
  mdiPencilOutline,
  mdiMapMarkerAlertOutline,
  mdiTruckRemoveOutline,
  mdiTruckCheckOutline,
  mdiPaperclipPlus,
  mdiPaperclipRemove,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import HistoryList from '@/components/history/HistoryList.vue'

export default {
  components: {
    SpinLoader,
    HistoryList,
  },
  props: {
    deliveryId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('history')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const historyList = []

    const labelList = [
      {
        type: '+',
        model: 'OrderDelivery',
        label: 'Cadastro de Entrega',
        icon: mdiTruckCheckOutline,
      },
      {
        type: '~',
        model: 'OrderDelivery',
        label: 'Edição de Entrega',
        icon: mdiPencilOutline,
      },
      {
        type: '~',
        model: 'OrderDelivery',
        label: 'Alteração de Endereço de Entrega',
        icon: mdiMapMarkerAlertOutline,
      },
      {
        type: '-',
        model: 'OrderDelivery',
        label: 'Exclusão de Entrega',
        icon: mdiTruckRemoveOutline,
      },
      {
        type: '+',
        model: 'OrderDeliveryFile',
        label: 'Inclusão de Anexos na Entrega',
        icon: mdiPaperclipPlus,
      },
      {
        type: '-',
        model: 'OrderDeliveryFile',
        label: 'Exclusão de Anexos na Entrega',
        icon: mdiPaperclipRemove,
      },
    ]

    return {
      isLoading,
      syncLoad,
      historyList,
      labelList,
    }
  },
  computed: {

  },
  created() {
    this.fetchHistorics()
  },
  methods: {
    fetchHistorics() {
      syslic
        .order
        .delivery
        .history
        .fetchHistory(this.deliveryId)
        .then(response => {
          this.historyList = response.data.events
          this.syncLoad.ready('history')
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar o histórico desta entrega.',
              text: 'Não foi possível carregar o histórico, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
          this.syncLoad.ready('history')
        })
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPencilOutline,
      mdiMapMarkerAlertOutline,
      mdiTruckRemoveOutline,
      mdiTruckCheckOutline,
      mdiPaperclipPlus,
      mdiPaperclipRemove,
    }
  },
}
</script>

<style lang="sass">

</style>
