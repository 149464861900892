<template>
  <div>
    <b-card no-body>
      <b-card-title class="m-0">
        <div class="deliveryHeader">
          <b-row>
            <b-col
              class="px-3 py-2"
            >
              <div>
                <span
                  class="d-inline deliveryInfoTitle"
                >
                  {{ delivery.client_set.name }}
                </span>
                <b-button
                  id="btn-link-delivery-info"
                  type="button"
                  variant="outline-info"
                  class="mb-1 mb-sm-0 mr-0 p-0"
                  @click="goToBidding()"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiLaunch"
                    size="18"
                  />
                </b-button>
              </div>
              <div>
                <div>
                  <span class="mr-50 deliveryInfoSubtitleLabel">CNPJ</span>
                  <span
                    v-show="delivery.client_set.cnpj"
                    class="deliveryInfoSubtitleContent"
                  >
                    {{ delivery.client_set.cnpj | VMask('##.###.###/####-##') }}
                  </span>
                  <span
                    v-show="!delivery.client_set.cnpj"
                    class="deliveryInfoSubtitleContent"
                  >
                    ...
                  </span>
                </div>
              </div>
            </b-col>
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                class="btn-icon status-btn right-rounded"
                :class="situationButtonClass"
                :disabled="!canEdit || (isInAudit && !haveAuditPermission) || (isDeclined) || (!canEdit && isInvoicing)"
                variant="white"
                @click="checkChangeDetections()"
              >
                <svg-icon
                  type="mdi"
                  size="36"
                  class="statusSvg"
                  :style="{ 'color': situationSvgColor }"
                  :path="situationSvgPath"
                />
                <span
                  class="statusText"
                >
                  {{ actionText }}
                </span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-title>
      <b-row
        class="p-2"
      >
        <b-col
          cols="12"
          md="2"
        >
          <validation-provider
            #default="validationContext"
            name="number_delivery"
            :rules="{
              required: true,
              regex:/^([0-9]+)$/
            }"
          >
            <b-form-group
              label="Nº da Entrega"
              label-for="number_delivery"
            >
              <b-form-input
                id="number_delivery"
                v-model="delivery.id"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="true"
                name="number_delivery"
                type="number"
                :placeholder="'Nº da Entrega'"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <company-select
            id="company"
            v-model="delivery.company_set"
            :readonly="true"
            :required-field="true"
            :truncate-field="17"
            @ready="syncLoad.ready('company')"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="date_delivery"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              label="Prazo de Entrega"
              label-for="date_delivery"
            >
              <b-form-datepicker
                id="id_date_delivery"
                v-model="delivery.date_delivery"
                :required="true"
                locale="pt-BR"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                size="md"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                label-no-date-selected="Nenhuma data foi selecionada"
                :disabled="readonly || isInvoicing"
                selected-variant="primary"
                placeholder="Selecione"
                label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                reset-button
                reset-button-variant="info"
                label-reset-button="Limpar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <delivery-status-select
            id="deliveryStatus"
            v-model="delivery.status_set"
            :readonly="readonly || isDone"
            :required-field="true"
            @ready="syncLoad.ready('statusDelivery')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="idAuditModal"
      ref="refAuditModal"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
    >
      <template #modal-title>
        Liberar?
      </template>

      <b-row>
        <b-col
          cols="12"
        >
          <div
            class="d-flex align-items-center justify-content-center p-3"
          >
            <h4
              class="m-0 text-center font-weight-bolder"
            >
              Deseja liberar a auditoria da Entrega?
            </h4>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <div
            class="d-flex justify-content-center align-items-center pb-2"
          >
            <b-button
              class="releaseModalBtn minModalBtnWidth"
              @click="checkNextSituation()"
            >
              Liberar
            </b-button>
            <b-button
              class="declineModalBtn ml-3 minModalBtnWidth"
              @click="changeDeliveryAuditSituation('declined')"
            >
              Declinar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="idModalUpdate"
      ref="modalImportingBid"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
    >
      <div class="pt-5 pb-4">
        <div class="d-flex justify-content-center">
          <b-spinner
            id="spinnerImport"
            variant="primary"
            type="grow"
          />
        </div>
        <h4
          class="d-flex justify-content-center mt-2 pl-50"
        >
          Atualizando...
        </h4>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BCardTitle,
  BCard,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  mdiCheckCircle,
  mdiLaunch,
  mdiNoteSearchOutline,
  mdiCashRegister,
} from '@mdi/js'

import formValidation from '@core/comp-functions/forms/form-validation'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import CompanySelect from '@/components/company/CompanySelect.vue'
import DeliveryStatusSelect from '@/components/order/DeliveryStatusSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BSpinner,
    ValidationProvider,
    CompanySelect,
    DeliveryStatusSelect,
    SvgIcon,
  },
  model: {
    prop: 'deliveryValue',
    event: 'deliveryChange',
  },
  props: {
    deliveryValue: {
      type: [Object, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInvoicing: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDone: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.delivery)

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('company')
    syncLoad.addTrigger('statusDelivery')

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    return {
      refFormObserver,
      getValidationState,
      syncLoad,
    }
  },
  computed: {
    delivery: {
      get() {
        return this.deliveryValue
      },
      set(value) {
        this.$emit('deliveryChange', value)
      },
    },
    isInAudit() {
      return this.delivery.situation === 'audit'
    },
    isDeclined() {
      return this.delivery.situation === 'declined'
    },
    haveAuditPermission() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.audit.delivery.can_audit
    },
    situationButtonClass() {
      const situationClassMap = {
        idle: 'undone-btn',
        invoicing: 'invoicing-btn',
        released: 'undone-btn',
        done: 'done-btn',
        audit: 'audit-btn',
        declined: 'declined-btn',
      }

      return situationClassMap[this.delivery.situation]
    },
    situationSvgColor() {
      const situationSvgColorMap = {
        idle: '#777777',
        invoicing: '#005C96',
        released: '#777777',
        done: '#049F14',
        audit: '#FF7E07',
        declined: '#F50F00',
      }

      return situationSvgColorMap[this.delivery.situation]
    },
    situationSvgPath() {
      const svgPathMap = {
        idle: mdiCashRegister,
        invoicing: mdiCashRegister,
        released: mdiCheckCircle,
        done: mdiCheckCircle,
        audit: mdiNoteSearchOutline,
        declined: mdiNoteSearchOutline,
      }

      return svgPathMap[this.delivery.situation]
    },
    actionText() {
      const situationTextMap = {
        idle: 'Enviar Entrega para Faturamento',
        invoicing: 'Entrega em Faturamento',
        released: 'Concluir Entrega',
        done: 'Entrega Concluída',
        audit: 'Entrega Bloqueada para Auditoria',
        declined: 'Entrega Declinada pela Auditoria',
      }

      return situationTextMap[this.delivery.situation]
    },
  },
  watch: {
    'delivery.company_set': function updateCompany(value) {
      if (value) {
        this.delivery.company = value.id
      } else {
        this.delivery.company = ''
        this.delivery.company_set = ''
      }
    },
    'delivery.status_set': function updateStatus(value) {
      if (value) {
        this.delivery.status = value.id
      } else {
        this.delivery.status = ''
        this.delivery.status_set = ''
      }
    },
  },
  created() {

  },
  methods: {
    goToBidding() {
      const routeData = router.resolve({
        name: 'bidding-view',
        params: {
          id: this.delivery.bidding,
        },
      })
      window.open(routeData.href, '_blank')
    },
    checkChangeDetections() {
      this.$emit('checkChanges')
    },
    changeDeliverySituation() {
      const actualSituation = this.delivery.situation

      if (actualSituation === 'audit' || actualSituation === 'declined') {
        this.$refs.refAuditModal.show()
      }
      this.showSituationChangeModal(actualSituation)
    },
    showSituationChangeModal(actualSituation) {
      const modalTextMap = {
        idle: 'Deseja enviar a entrega para faturamento?',
        invoicing: 'Deseja REABRIR essa entrega?',
        released: 'Deseja CONCLUIR essa entrega e finalizar todo o processo?',
        done: 'Deseja REABRIR essa entrega?',
      }
      if (actualSituation === 'invoicing') {
        this.$bvModal
          .msgBoxConfirm(modalTextMap[actualSituation], {
            id: 'confirmation_box',
            title: 'Confirmar?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: true,
            footerClass: 'd-flex justify-content-center pb-2',
            bodyClass: 'text-center pt-3 px-3 pb-1 font-weight-bolder',
          })
          .then(value => {
            if (value) {
              this.checkSituationChange(actualSituation)
            }
          })
      } else if (!(actualSituation === 'audit') && !(actualSituation === 'declined')) {
        this.$bvModal
          .msgBoxConfirm(modalTextMap[actualSituation], {
            id: 'confirmation_box',
            title: 'Confirmar?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: true,
            footerClass: 'd-flex justify-content-center pb-2',
            bodyClass: 'text-center pt-3 px-3 pb-1 font-weight-bolder',
          })
          .then(value => {
            if (value) {
              this.$emit('changeBasicSituation', actualSituation)
            }
          })
      }
    },
    checkSituationChange(actualSituation) {
      this.$refs.modalImportingBid.show()
      syslic
        .order
        .delivery
        .hasInvoicing(this.delivery.id)
        .then(response => {
          const hasInvoicing = response.data.has_invoicing

          if (hasInvoicing) {
            this.$refs.modalImportingBid.hide()
            this.$bvModal
              .msgBoxOk('É necessário apagar os faturamentos vinculados a esta entrega para poder voltar ao estado de Entrega Aberta.', {
                title: 'Informação',
                buttonSize: 'md',
                centered: true,
                size: 'sm',
                footerClass: 'd-flex justify-content-center pb-2',
                bodyClass: 'text-center pt-3 px-3 pb-1 font-weight-bolder',
              })
          } else {
            this.$emit('changeBasicSituation', actualSituation)
            this.$refs.modalImportingBid.hide()
          }
        })
        .catch(() => {
          this.$refs.modalImportingBid.hide()
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao verificar existência de Faturamento com esta Entrega.',
              text:
                'Não foi possível verificar se existe faturamento criado para essa entrega, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    changeDeliveryAuditSituation(situation) {
      this.$refs.modalImportingBid.hide()
      this.$refs.refAuditModal.hide()
      this.$emit('changeAuditSituation', situation)
    },
    checkNextSituation() {
      this.$refs.refAuditModal.hide()
      this.$refs.modalImportingBid.show()

      syslic
        .order
        .delivery
        .hasInvoicing(this.delivery.id)
        .then(response => {
          this.changeDeliveryAuditSituation(response.data.next_situation)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao verificar existência de Faturamento com esta Entrega.',
              text:
                'Não foi possível verificar se existe faturamento criado para essa entrega, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
  },
  setup() {
    return {
      mdiCheckCircle,
      mdiLaunch,
      mdiNoteSearchOutline,
      mdiCashRegister,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

.deliveryHeader {
  background-color: #F6F6F9;
}

.deliveryInfoTitle {
  color: $primary;
  text-transform: uppercase;
}

.deliveryInfoSubtitleLabel {
  color: $primary;
  font-weight: bold;
  font-size: 1rem;
}

.deliveryInfoSubtitleContent {
  color: #494949;
  font-size: 1rem;
}

#btn-link-delivery-info {
  border: 0px !important;
}

.status-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .4em;
  max-width: 9em;
}

.right-rounded {
  border-radius: 0 1em 0 0;
}

.statusText {
  color: #ffffff;
  font-size: .9em;
  font-weight: 600;
  margin: 0;
}

.statusSvg {
  background-color: #ffffff;
  border-radius: 50%;
  padding: .4em;
}

.done-btn {
  background-color: #049F14;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475;
  }
}

.undone-btn {
  background-color: #777777;

  &:hover {
    box-shadow: 0 .4rem .6rem #77777775;
  }
}

.invoicing-btn {
  background-color: #005C96;

  &:hover {
    box-shadow: 0 .4rem .6rem #005c9675;
  }
}

.audit-btn {
  background-color: #FF7E07;

  &:hover {
    box-shadow: 0 .4rem .6rem #FF7E0775;
  }
}

.declined-btn {
  background-color: #F50F00;

  &:hover {
    box-shadow: 0 .4rem .6rem #F50F0075;
  }
}

.releaseModalBtn {
  background-color: #049F14 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #049F1475 !important;
  }
}

.declineModalBtn {
  background-color: #F50F00 !important;
  border: none !important;

  &:hover {
    box-shadow: 0 .4rem .6rem #F50F0075 !important;
  }
}

.minModalBtnWidth {
  min-width: 8rem !important;
}

#idModalUpdate {
  #idModalImportingBid___BV_modal_content_{
    border-radius:20px !important;
  }
}
</style>
