<template>
  <div>
    <b-row
      class="m-0"
    >
      <b-col
        class="clsDeliveryAddressDelivery p-50"
      >
        <span class="ml-50">Endereço da Entrega</span>
      </b-col>
    </b-row>
    <b-row class="px-2">
      <b-col class="p-0 my-1">
        <address-edit-fields
          id="address-fields-component"
          class="px-1"
          :address="delivery"
          :is-view-mode="readonly"
          :required-fields="[]"
          :enabled-fields="enabledFieldsAddress"
          :zip-code-field="'zip_code'"
          :address-field="'address'"
          :number-field="'number'"
          :complement-field="'complement'"
          :neighborhood-field="'neighborhood'"
          :country-field="'country'"
          :state-field="'state'"
          :city-field="'city'"
          @ready="syncLoad.ready('address')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import AddressEditFields from '@/components/address/AddressEditFields.vue'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    AddressEditFields,
    BRow,
    BCol,
  },
  model: {
    prop: 'deliveryValue',
    event: 'deliveryChange',
  },
  props: {
    deliveryValue: {
      type: [Object, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const enabledFieldsAddress = [
      'zip_code',
      'address',
      'number',
      'complement',
      'neighborhood',
      'state',
      'city',
      'country',
    ]
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('address')

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    return {
      syncLoad,
      enabledFieldsAddress,
    }
  },
  computed: {
    delivery: {
      get() {
        return this.deliveryValue
      },
      set(value) {
        this.$emit('deliveryChange', value)
      },
    },
  },
  created() {
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.clsDeliveryAddressDelivery {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
  background-color: #E3EDF3;
}
</style>
