<template>
  <div>
    <div>
      <b-row>
        <b-col
          class="text-center px-0 py-2"
          cols="12"
          md="1"
        >
          <b-form-checkbox
            v-model="cotation.accepted"
            class="w-100 clsBtnFreight"
            :class="(readonly || !cotation.quote_number || !cotation.cost || !cotation.carrier) ? 'disabled' : ''"
            name="check-button"
            button
            size="sm"
            :button-variant="cotation.accepted ? 'success' : 'outline-info'"
            :disabled="readonly || !cotation.quote_number || !cotation.cost || !cotation.carrier"
          >
            <span
              v-if="cotation.accepted"
            >
              Contratado
            </span>
            <span
              v-else
            >
              Em Cotação
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex px-50"
        >
          <b-row>
            <b-col
              cols="12"
              md="5"
              class="pr-50"
            >
              <validation-provider
                v-slot="validationContext"
                name="quote_number"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group
                  label="Número da Cotação"
                  label-for="quote_number"
                >
                  <b-form-input
                    id="quote_number"
                    v-model="cotation.quote_number"
                    :state="readonly ? null : getValidationState(validationContext)"
                    type="number"
                    :placeholder="readonly ? '' : '0000'"
                    :readonly="readonly"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="7"
              class="pl-50"
            >
              <carrier-select
                id="carrier"
                v-model="cotation.carrier_set"
                :readonly="readonly"
                :required-field="true"
                :truncate-field="17"
                @ready="syncLoad.ready('carrier')"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            #default="validationContext"
            name="phone_number"
            :rules="{
              required: false,
              min: 14,
            }"
          >
            <b-form-group
              label="Telefone"
              label-for="phone_number"
            >
              <b-form-input
                id="phone_number"
                v-model="cotation.phone_number"
                v-mask="getMask()"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                :placeholder="readonly ? '' : '(99) 99999-9999'"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            #default="validationContext"
            name="email"
            :rules="{
              required: false,
              email: true,
            }"
          >
            <b-form-group
              label="E-mail"
              label-for="e-mail"
            >
              <b-form-input
                id="e-mail"
                v-model="cotation.email"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                trim
                :placeholder="readonly ? '' : 'exemplo@syslic.com.br'"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <validation-provider
            v-slot="{ errors }"
            name="date_cotation"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              label="Data da Cotação"
              label-for="date_cotation"
            >
              <b-form-datepicker
                v-model="cotation.date_cotation"
                :required="true"
                locale="pt-BR"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                size="md"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                label-no-date-selected="Nenhuma data foi selecionada"
                :disabled="readonly"
                selected-variant="primary"
                placeholder="Selecione"
                label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                reset-button
                reset-button-variant="info"
                label-reset-button="Limpar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="px-50"
        >
          <b-form-group
            label="Valor do Frete"
            label-for="freight_value"
          >
            <cleave-customized
              v-model="cotation.cost"
              :option="options.price"
              :places="2"
              :required-field="true"
              :readonly="readonly"
              :field-name="'freight_value'"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormCheckbox,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import formValidation from '@core/comp-functions/forms/form-validation'
import SyncLoad from '@/components/utils/syncLoad'
import CleaveCustomized from '@/components/utils/CleaveCustomized.vue'
import CarrierSelect from '@/components/transport/CarrierSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormCheckbox,
    ValidationProvider,
    CleaveCustomized,
    CarrierSelect,
  },
  model: {
    prop: 'cotationValue',
    event: 'cotationChange',
  },
  props: {
    cotationValue: {
      type: [Object, String],
      required: true,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.delivery)

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('carrier')

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    const options = {
      price: {
        numeral: true,
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
      },
    }

    return {
      refFormObserver,
      getValidationState,
      syncLoad,
      options,
    }
  },
  computed: {
    cotation: {
      get() {
        return this.cotationValue
      },
      set(value) {
        this.$emit('cotationChange', value)
      },
    },
  },
  watch: {
    'cotation.carrier_set': function updateCarrier(value) {
      if (value) {
        this.cotation.carrier = value.id
        this.cotation.phone_number = value.phone_number
        this.cotation.email = value.email
      } else {
        this.cotation.carrier = ''
        this.cotation.carrier_set = ''
        this.cotation.phone_number = ''
        this.cotation.email = ''
      }
    },
    'cotation.accepted': function updateAccepted(value) {
      if (value) {
        this.$emit('accepted', true)
      }
    },
  },
  created() {

  },
  methods: {
    getMask() {
      let mask = '(##) ####-####'
      if (this.cotation.phone_number) {
        if (this.cotation.phone_number.length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.clsOrderAddressDelivery {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
  background-color: #E3EDF3;
}

.clsBtnFreight .btn {
  min-width: 8rem;
}

.clsBtnFreight .disabled {
  opacity: 0.4 !important;
}

#address-fields-component .card-body{
  padding: 0px;
}
</style>
