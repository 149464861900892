<template>
  <div>
    <b-row
      class="m-0"
    >
      <b-col
        class="clsDeliveryResult p-50"
      >
        <span class="ml-50">Resumo da Entrega</span>
      </b-col>
    </b-row>
    <div>
      <b-row
        class="px-2"
      >
        <b-col
          cols="12"
          md="2"
        >
          <p class="mt-1 mb-50 clsDeliveryResultHeaderTitle">
            Valor da Entrega
          </p>
          <p class="mb-2 clsDeliveryResultHeaderContent">
            R$ {{ deliveryTotalValue | formatCurrency() }}
          </p>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <p class="mt-1 mb-50 clsDeliveryResultHeaderTitle">
            Frete Estimado
          </p>
          <p class="mb-2 clsDeliveryResultHeaderContent">
            R$ {{ biddingFreightValue | formatCurrency() }}
          </p>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <p class="mt-1 mb-50 clsDeliveryResultHeaderTitle">
            Frete Realizado
          </p>
          <p class="mb-2 clsDeliveryResultHeaderContent">
            R$ {{ deliveryFreightValue | formatCurrency() }}
          </p>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <p class="mt-1 mb-50 clsDeliveryResultHeaderTitle">
            % do Frete
          </p>
          <p class="mb-2 clsDeliveryResultHeaderContent">
            <template
              v-if="deliveryTotalValue > 0 && deliveryFreightValue > 0"
            >
              {{ deliveryFreightPercentage | formatCurrency() }} %
            </template>
            <template v-else>
              -
            </template>
          </p>
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <p class="mt-1 mb-50 clsDeliveryResultHeaderTitle">
            Margem de Lucro
          </p>
          <p
            v-if="deliveryTotalValue === 0"
            class="mb-2 clsDeliveryResultHeaderContent"
          >
            -
          </p>
          <p
            v-else
            class="mb-2 clsDeliveryResultHeaderContent"
          >
            {{ deliveryMarginValue | formatCurrency() }} %
          </p>
        </b-col>
        <b-col
          cols="12"
          md="3"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import formatCurrency from '@/components/utils/filters/formatCurrency'

export default {
  components: {
    BRow,
    BCol,
  },
  filters: {
    formatCurrency,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    cotations: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    deliveryTotalValue() {
      let value = 0

      this.items.forEach(obj => {
        value += (parseFloat(obj.item_set.contract_item.price) * obj.quantity)
      })

      return value
    },
    biddingFreightValue() {
      let value = 0

      this.items.forEach(obj => {
        value += (parseFloat(obj.item_set.contract_item.price) * (parseFloat(obj.item_set.contract_item.freight) / 100) * obj.quantity)
      })

      return value
    },
    deliveryCostValue() {
      let value = 0

      this.items.forEach(obj => {
        let prodValue = 0

        obj.item_set.products_set.forEach(prod => {
          prodValue += (parseFloat(prod.cost) * prod.product_set.quantity)
        })

        value += prodValue * obj.quantity
      })

      return value
    },
    deliveryFreightValue() {
      let value = 0

      this.cotations.forEach(obj => {
        if (obj.accepted) {
          value = parseFloat(obj.cost)
        }
      })

      return value
    },
    deliveryFreightPercentage() {
      const freightValue = this.deliveryFreightValue
      const deliveryValue = this.deliveryTotalValue
      const percentage = deliveryValue > 0 && freightValue > 0 ? (freightValue / deliveryValue) * 100 : 0

      return percentage
    },
    deliveryTaxValue() {
      let value = 0

      this.items.forEach(obj => {
        value += (Number(obj.item_set.contract_item.price) * (Number(obj.item_set.contract_item.tax) / 100) * obj.quantity)
      })

      return value
    },
    deliveryFixedCostValue() {
      let value = 0

      this.items.forEach(obj => {
        value += (Number(obj.item_set.contract_item.price) * (Number(obj.item_set.contract_item.fixed_cost) / 100) * obj.quantity)
      })

      return value
    },
    deliveryMarginValue() {
      const price = this.deliveryTotalValue
      const cost = this.deliveryCostValue
      const freight = this.deliveryFreightValue
      const tax = this.deliveryTaxValue
      const fixed = this.deliveryFixedCostValue

      const margin = (1 - ((cost + freight + tax + fixed) / price)) * 100 || 0

      return margin
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.clsDeliveryResult {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
  background-color: #E3EDF3;
}

.clsDeliveryResultHeaderTitle {
  color: $primary;
  font-weight: 600;
  font-size: 1.125rem;
}

.clsDeliveryResultHeaderContent {
  color: #464646;
}

</style>
