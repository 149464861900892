var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"clsOrderTransportCotation p-50"},[_c('span',{staticClass:"ml-50"},[_vm._v("Dados da Entrega")])])],1),_c('div',[_c('b-row',{staticClass:"p-2"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"invoicing_delivery_date","rules":{
            required: false,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data da Entrega","label-for":"invoicing_delivery_date","label-class":'formLabel'}},[_c('b-form-datepicker',{class:errors.length > 0 ? 'form-control is-invalid' : 'form-control',attrs:{"id":"id_invoicing_delivery_date","required":false,"locale":"pt-BR","size":"md","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"label-no-date-selected":"Nenhuma data foi selecionada","disabled":_vm.readonly,"selected-variant":"primary","placeholder":"Selecione","label-help":"Use o teclado ou o mouse para navegar pelas datas do calendário","reset-button":"","reset-button-variant":"info","label-reset-button":"Limpar"},model:{value:(_vm.delivery.invoicing_delivery_date),callback:function ($$v) {_vm.$set(_vm.delivery, "invoicing_delivery_date", $$v)},expression:"delivery.invoicing_delivery_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"expedition_date","rules":{
            required: false,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data da Expedição","label-for":"expedition_date","label-class":'formLabel'}},[_c('b-form-datepicker',{class:errors.length > 0 ? 'form-control is-invalid' : 'form-control',attrs:{"id":"expedition_date","required":false,"locale":"pt-BR","size":"md","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"label-no-date-selected":"Nenhuma data foi selecionada","disabled":_vm.readonly,"selected-variant":"primary","placeholder":"Selecione","label-help":"Use o teclado ou o mouse para navegar pelas datas do calendário","reset-button":"","reset-button-variant":"info","label-reset-button":"Limpar"},model:{value:(_vm.delivery.expedition_date),callback:function ($$v) {_vm.$set(_vm.delivery, "expedition_date", $$v)},expression:"delivery.expedition_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"driver_name","rules":{
            required: false,
          }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Nome do Motorista","label-for":"driver_name","label-class":'formLabel'}},[_c('b-form-input',{attrs:{"id":"driver_name","state":_vm.readonly ? null : _vm.getValidationState(validationContext),"readonly":_vm.readonly,"name":"driver_name","type":"text","placeholder":"Nome do Motorista"},model:{value:(_vm.delivery.driver_name),callback:function ($$v) {_vm.$set(_vm.delivery, "driver_name", $$v)},expression:"delivery.driver_name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }