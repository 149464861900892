<template>
  <div>
    <b-row
      class="m-0"
    >
      <b-col
        class="clsOrderTransportCotation p-50"
      >
        <span class="ml-50">Cotação de Frete</span>
      </b-col>
    </b-row>
    <div>
      <b-row
        v-if="cotationList.length === 0"
        class="clsEmptyFreight p-1 m-0"
      >
        <b-col
          class="text-center my-2"
        >
          <span>Não há cotações de frete cadastrados para entrega.</span>
        </b-col>
      </b-row>
      <b-row
        v-else
        class="px-2 pt-2 pb-0"
      >
        <b-col>
          <div
            v-for="(obj, index) in cotationList"
            :key="index"
          >
            <b-row>
              <b-col>
                <div class="d-flex">
                  <delivery-transport-cotation
                    v-model="cotationList[index]"
                    class="d-inlinei w-100 mr-50"
                    :readonly="readonly"
                    :required-field="true"
                    :truncate-field="17"
                    @accepted="updateAccepted(index)"
                    @ready="syncLoad.ready('cotations')"
                  />
                  <div
                    v-if="!readonly"
                    class="d-inline"
                  >
                    <b-row
                      class="h-100 py-2"
                    >
                      <b-col align-self="start">
                        <b-button
                          id="buttonDel"
                          class="btn-icon rounded-circle"
                          variant="flat-info"
                          size="md"
                          @click="removeCotation(index)"
                        >
                          <svg-icon
                            type="mdi"
                            size="18"
                            :path="mdiTrashCanOutline"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!readonly">
        <b-col
          id="btnAddCotationParent"
          class="d-flex justify-content-end mr-50"
        >
          <b-button
            id="btnAddCotation"
            class="btn-icon rounded-circle mt-1 mb-0 mr-2"
            variant="primary"
            size="md"
            title="Adicionar Item"
            @click="addCotation()"
          >
            <svg-icon
              type="mdi"
              :path="mdiPlus"
            />
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import DeliveryTransportCotation from './DeliveryTransportCotation.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    SvgIcon,
    DeliveryTransportCotation,
  },
  props: {
    cotationList: {
      type: [Array],
      required: true,
    },
    deliveryId: {
      type: [String, Number],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const cotation = {
      delivery: '',
      carrier: '',
      carrier_set: '',
      phone_number: '',
      email: '',
      date_cotation: '',
      cost: '',
      accepted: false,
    }

    const isLoading = false

    const cotationRemoved = []

    const syncLoad = new SyncLoad()

    syncLoad.onReady(() => {
      this.$emit('ready', true)
    })

    return {
      syncLoad,
      cotation,
      isLoading,
      cotationRemoved,
    }
  },
  created() {
    this.checkCotations()
  },
  methods: {
    addCotation() {
      const blankCotation = JSON.parse(JSON.stringify(this.cotation))
      blankCotation.date_cotation = moment().format('YYYY-MM-DD')
      blankCotation.delivery = this.deliveryId
      this.cotationList.push(blankCotation)
    },
    removeCotation(index) {
      if ('id' in this.cotationList[index]) {
        this.cotationRemoved.push(JSON.parse(JSON.stringify(this.cotationList[index])))
      }
      this.cotationList.splice(index, 1)
    },
    updateAccepted(indexAccepted) {
      this.cotationList.forEach((obj, index) => {
        if (indexAccepted === index) {
          this.cotationList[index].accepted = true
        } else {
          this.cotationList[index].accepted = false
        }
      })
    },
    save() {
      const promiseList = []

      this.cotationRemoved.forEach(obj => {
        const promise = syslic.order.delivery.freight.deleteFreight(obj.id)
        promiseList.push(promise)
      })

      this.cotationList.forEach(obj => {
        let promise = null

        if ('id' in obj) {
          promise = syslic.order.delivery.freight.updateFreight(obj.id, obj)
        } else {
          promise = syslic.order.delivery.freight.addFreight(obj)
        }
        promiseList.push(promise)
      })

      this.cotationRemoved = []
      return Promise.all(promiseList)
    },
    checkCotations() {
      if (this.cotationList.length > 0) {
        this.syncLoad.addTrigger('cotations')
      } else {
        this.$emit('ready', true)
      }
    },
  },
  setup() {
    return {
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.clsOrderTransportCotation {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
  background-color: #E3EDF3;
}

.clsTransportAnotation {
  min-height: 8.25rem;
}

.clsEmptyFreight {
  background-color: #fafafc;
}

#buttonDel {
  color: #494949 !important;
}

</style>
