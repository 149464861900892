<template>
  <div>
    <div id="idItemDeliveryList">
      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            ref="refItemTable"
            thead-class="itemTableHeader"
            primary-key="id"
            responsive
            show-empty
            striped
            hover
            empty-text="Não há itens cadastrados para entrega."
            :items="itemsTable"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            :busy="loadingState"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <b-spinner
                  variant="info"
                  class="align-middle mb-1"
                />
                <br>
                <strong class="pt-2">Carregando...</strong>
              </div>
            </template>

            <template #cell(field_bidding_number)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap text-color-primary"
                >
                  #{{ field.item.biddingNumber }}
                </span>
              </b-media>
            </template>

            <template #cell(field_name_bidding)="field">
              <b-media vertical-align="center">
                <span
                  :id="`name_bidding${field.item.idItem}`"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.name_bidding }}
                </span>
                <b-popover
                  :target="`name_bidding${field.item.idItem}`"
                  triggers="hover"
                  placement="bottomright"
                >
                  <span><b>Item na Licitação: </b> {{ field.item.name_bidding }}</span>
                  <br>
                  <span><b>Valor Unitário: </b> R$ {{ field.item.price }}</span>
                  <br>
                  <span><b>Quantidade: </b> {{ field.item.qtd }}</span>
                </b-popover>
              </b-media>
            </template>

            <template #cell(field_name_register)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.name_register }}
                </span>
              </b-media>
            </template>

            <template #cell(field_qtd)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.qtd }}
                </span>
              </b-media>
            </template>

            <template #cell(field_width)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.width | formatCurrency() }} m
                </span>
              </b-media>
            </template>

            <template #cell(field_height)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.height | formatCurrency() }} m
                </span>
              </b-media>
            </template>

            <template #cell(field_lenght)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.lenght | formatCurrency() }} m
                </span>
              </b-media>
            </template>

            <template #cell(field_volume)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.volume | formatCurrency() }} m³
                </span>
              </b-media>
            </template>

            <template #cell(field_weight)="field">
              <b-media vertical-align="center">
                <span
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ field.item.weight | formatCurrency() }} kg
                </span>
              </b-media>
            </template>

            <template #cell(field_actions)="field">
              <b-button
                v-if="!readonly"
                id="buttonDel"
                class="btn-icon rounded-circle"
                variant="flat-info"
                size="md"
                @click="showConfirmOrCancelDelete(field.item)"
              >
                <svg-icon
                  type="mdi"
                  size="18"
                  :path="mdiTrashCanOutline"
                />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div>
        <delivery-item-modal
          :delivery-id="deliveryId"
          :order-id="orderId"
          :delivery-items="itemsDelivery"
          :readonly="readonly"
          @update="getUpdatedItems()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BMedia,
  BButton,
  BPopover,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'

import {
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
  mdiTruckRemoveOutline,
  mdiTruckDeliveryOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import DeliveryItemModal from './DeliveryItemModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    BButton,
    BPopover,
    SvgIcon,
    BSpinner,
    DeliveryItemModal,
  },
  filters: {
    formatCurrency,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    itemList: {
      type: Array,
      required: true,
    },
    deliveryId: {
      type: [Number, String],
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    loadingState: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_bidding_number',
        label: 'Nº Item',
        thStyle: { wminWidth: '18rem' },
      },
      {
        key: 'field_name_bidding',
        label: 'Item na Licitação',
        thStyle: { wminWidth: '18rem' },
      },
      {
        key: 'field_name_register',
        label: 'Nome do Produto no Cadastro',
        thStyle: { wminWidth: '18rem' },
      },
      {
        key: 'field_qtd',
        label: 'Quantidade de Produtos',
        thStyle: { minWidth: '18rem' },
      },
      {
        key: 'field_height',
        label: 'Altura',
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'field_width',
        label: 'Largura',
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'field_lenght',
        label: 'Profundidade',
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'field_volume',
        label: 'Volume',
        thStyle: { minWidth: '10rem' },
      },
      {
        key: 'field_weight',
        label: 'Peso',
        thStyle: { minWidth: '10rem' },
      },
    ]
    if (!this.readonly) {
      tableColumns.push({
        key: 'field_actions',
        label: '',
        thStyle: { width: '10rem' },
      })
    }

    const itemsDelivery = []

    const itemsTable = []

    const itemValue = {
      annotation: '',
      contract_item: {
        product_list: [{
          product_set: '',
        }],
      },
      delivery: '',
      cost: '',
      id: '',
      item: '',
      quantity: '',
    }

    return {
      refFormObserver,
      getValidationState,
      sortBy,
      tableColumns,
      isSortDirDesc,
      itemsDelivery,
      itemsTable,
      itemValue,
    }
  },
  watch: {
    itemList: {
      handler: function handleList(val) {
        if (val) {
          this.handleItemsData(val)
        }
      },
      deep: true,
    },
  },
  methods: {
    handleItemsData(data) {
      this.itemsDelivery = JSON.parse(JSON.stringify(data))
      this.mountTableData(data)
    },
    mountTableData(items) {
      this.itemsTable = []

      items.forEach((element, index) => {
        element.item_set.contract_item.product_list.forEach(item => {
          const newItem = {
            biddingNumber: element.item_set.contract_item.number,
            idItem: element.id,
            indexItem: index,
            type: element.item_set.contract_item.type,
            name_register: item.product_set.name,
            name_bidding: element.item_set.contract_item.name,
            price: this.currencyFormat(element.item_set.contract_item.price),
            qtd: element.quantity,
            width: item.product_set.width,
            height: item.product_set.height,
            weight: item.product_set.weight,
            lenght: item.product_set.lenght,
            volume: item.product_set.lenght * item.product_set.height * item.product_set.width,
          }

          this.itemsTable.push(newItem)
        })
      })

      if ('refItemTable' in this.$refs) {
        this.$refs.refItemTable.refresh()
      }
    },
    showConfirmOrCancelDelete(item) {
      let text = 'Por favor, confirme que você quer remover o item da entrega.'
      if (item.type === 'compound') {
        text = 'Item composto, a exclusão apagará também as linhas com produtos associados a ele!'
      }
      this.$bvModal
        .msgBoxConfirm(`${text}`, {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteItem(item.idItem)
          }
        })
    },
    deleteItem(id) {
      syslic
        .order
        .delivery
        .item
        .deleteDeliveryItem(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Item removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.removeListItem(id)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover item.',
              text: 'Não foi possível remover o item da entrega do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    removeListItem(id) {
      const newArray = []
      let index = null

      this.itemsTable.forEach(element => {
        if (element.idItem !== id) {
          newArray.push(element)
        } else if (element.indexItem !== index) {
          index = element.indexItem
          this.itemsDelivery.splice(index, 1)
        }
      })
      this.itemsTable = newArray
      this.$refs.refItemTable.refresh()
      this.$emit('refresh', this.itemsDelivery)
    },
    currencyFormat(value) {
      return value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    getUpdatedItems() {
      this.$emit('update-list')
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
      mdiTruckRemoveOutline,
      mdiTruckDeliveryOutline,
      mdiTrashCanOutline,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idItemDeliveryList .itemTableHeader th {
  color: $primary;
  background-color: #E3EDF3;
  vertical-align: middle;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#idItemDeliveryList .itemListHeader {
  background-color: #F6F6F9
}

#idItemDeliveryList .headerTitle {
  color: $primary;
  font-weight: 600;
  font-size: 0.9rem;
}

#idItemDeliveryList .headerContent {
  color: #464646;
}

#idItemDeliveryList .iconDeliverySuccess {
  color: #02B714;
}

#idItemDeliveryList .clsOrderDeliveryItensTitle {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
  background-color: #E3EDF3;
}

#idItemDeliveryList .biddingIconType {
  color: $primary;
}

#buttonDel {
  color: #494949 !important;
}

.text-color-primary {
  color: $primary;
}
</style>
