<template>
  <div>
    <b-row
      class="m-0"
    >
      <b-col
        class="clsOrderTransportCotation p-50"
      >
        <span class="ml-50">Dados da Entrega</span>
      </b-col>
    </b-row>
    <div>
      <b-row
        class="p-2"
      >
        <b-col
          cols="12"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="invoicing_delivery_date"
            :rules="{
              required: false,
            }"
          >
            <b-form-group
              label="Data da Entrega"
              label-for="invoicing_delivery_date"
              :label-class="'formLabel'"
            >
              <b-form-datepicker
                id="id_invoicing_delivery_date"
                v-model="delivery.invoicing_delivery_date"
                :required="false"
                locale="pt-BR"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                size="md"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                label-no-date-selected="Nenhuma data foi selecionada"
                :disabled="readonly"
                selected-variant="primary"
                placeholder="Selecione"
                label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                reset-button
                reset-button-variant="info"
                label-reset-button="Limpar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            name="expedition_date"
            :rules="{
              required: false,
            }"
          >
            <b-form-group
              label="Data da Expedição"
              label-for="expedition_date"
              :label-class="'formLabel'"
            >
              <b-form-datepicker
                id="expedition_date"
                v-model="delivery.expedition_date"
                :required="false"
                locale="pt-BR"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                size="md"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                label-no-date-selected="Nenhuma data foi selecionada"
                :disabled="readonly"
                selected-variant="primary"
                placeholder="Selecione"
                label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                reset-button
                reset-button-variant="info"
                label-reset-button="Limpar"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="driver_name"
            :rules="{
              required: false,
            }"
          >
            <b-form-group
              label="Nome do Motorista"
              label-for="driver_name"
              :label-class="'formLabel'"
            >
              <b-form-input
                id="driver_name"
                v-model="delivery.driver_name"
                :state="readonly ? null : getValidationState(validationContext)"
                :readonly="readonly"
                name="driver_name"
                type="text"
                placeholder="Nome do Motorista"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormInvalidFeedback,
  BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  model: {
    prop: 'delivery',
    event: 'deliveryChange',
  },
  props: {
    delivery: {
      type: [Object],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.delivery)

    return {
      refFormObserver,
      getValidationState,
    }
  },
  watch: {
    'delivery.invoicing_delivery_date': function updateInvoicingDate(val) {
      if (val === '') {
        this.delivery.invoicing_delivery_date = null
      }
    },
    'delivery.expedition_date': function updateExpeditionDate(val) {
      if (val === '') {
        this.delivery.expedition_date = null
      }
    },
  },
  created() {
    this.$emit('ready', true)
  },
  methods: {
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";
</style>
