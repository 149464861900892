<template>
  <div>
    <b-row
      class="m-0"
    >
      <b-col
        class="clsDeliveryAnnotation p-50"
      >
        <span class="ml-50">Anotações</span>
      </b-col>
    </b-row>
    <div>
      <b-row
        class="p-2"
      >
        <b-col>
          <b-form-textarea
            id="annotation"
            v-model="delivery.annotation"
            class="clsDeliveryAnnotationField"
            trim
            :placeholder="'Anotações'"
            :readonly="readonly"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormTextarea,
  },
  model: {
    prop: 'delivery',
    event: 'deliveryChange',
  },
  props: {
    delivery: {
      type: [Object],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  created() {
    this.$emit('ready', true)
  },
  methods: {
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

.clsDeliveryAnnotation {
  font-size: 1rem;
  font-weight: 600;
  color: $primary;
  background-color: #E3EDF3;
}

.clsDeliveryAnnotationField {
  min-height: 8.25rem;
}

</style>
