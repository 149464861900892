<template>
  <div>
    <div>
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <b-modal
          id="idModalItemDelivery"
          ref="modalItemDelivery"
          dialog-class="mx-3 px-3"
          no-close-on-backdrop
          no-close-on-esc
          centered
          :size="emptyItemsToDelivery ? 'lg' : 'xl'"
          cancel-variant="outline-primary"
          footer-bg-variant="light"
          :ok-disabled="invalid"
          :hide-footer="emptyItemsToDelivery"
          @ok="handleSubmit(save)"
        >
          <template #modal-title>
            Selecione os itens de cada empenho que deseja incluir na entrega
          </template>
          <template #modal-cancel>
            Cancelar
          </template>
          <template #modal-ok>
            Adicionar
          </template>

          <div
            v-if="isLoading"
            class="d-flex justify-content-center align-items-center"
            :style="{ 'min-height': '14rem' }"
          >
            <spin-loader
              id="modalItemSpinLoader"
            />
          </div>

          <b-form
            v-else
            class="commitmentList"
          >
            <template v-if="emptyItemsToDelivery">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div
                    class="d-flex align-items-center justify-content-center flex-column p-5"
                  >
                    <svg-icon
                      class="text-primary"
                      type="mdi"
                      :path="mdiTruckAlertOutline"
                      size="48"
                    />
                    <h4
                      class="font-weight-bold text-center mt-25 m-0"
                    >
                      Não há itens marcados para entrega nos empenhos que pertencem ao pedido.
                    </h4>
                  </div>
                </b-col>
              </b-row>
            </template>

            <template v-else>
              <b-row
                v-for="(commitment, index) in itemsDelivery"
                :key="index"
              >
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-row
                    class="commitmentDetail px-3 pt-50 pb-1"
                  >
                    <b-col
                      cols="12"
                      md="12"
                      class="d-flex align-items-center justify-content-start"
                    >
                      <h4
                        class="text-white font-weight-bolder"
                      >
                        N° do Empenho {{ commitment.commitment_set.number }}
                      </h4>
                      <b-button
                        type="button"
                        variant="link"
                        class="mb-1 ml-1 d-flex align-items-center justify-content-start commitmentLinkBtn"
                        @click="goToCommitment(commitment.commitment)"
                      >
                        <svg-icon
                          class="text-white"
                          type="mdi"
                          :path="mdiLaunch"
                          size="28"
                        />
                      </b-button>
                    </b-col>
                    <b-col
                      cols="12"
                      class="flexStartWithGap"
                    >
                      <div
                        class="flexStartWithGap"
                      >
                        <p
                          class="text-white font-weight-bolder"
                        >
                          Empresa
                        </p>
                        <span
                          class="text-white"
                        >
                          {{ commitment.commitment_set.company_set.name_fantasy }}
                        </span>
                      </div>
                      <div
                        class="flexStartWithGap"
                      >
                        <p
                          class="text-white font-weight-bolder"
                        >
                          Prazo de Entrega
                        </p>
                        <span
                          class="text-white"
                        >
                          <template v-if="commitment.commitment_set.date_expiration">
                            {{ moment(commitment.commitment_set.date_expiration).format('DD/MM/YYYY') }}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </span>
                      </div>
                      <div
                        class="flexStartWithGap"
                      >
                        <p
                          class="text-white font-weight-bolder"
                        >
                          Recebimento do Empenho
                        </p>
                        <span
                          class="text-white"
                        >
                          <template v-if="commitment.commitment_set.date_delivery">
                            {{ moment(commitment.commitment_set.date_delivery).format('DD/MM/YYYY') }}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </span>
                      </div>
                      <div
                        class="flexStartWithGap"
                      >
                        <p
                          class="text-white font-weight-bolder"
                        >
                          Status do Empenho
                        </p>
                        <span
                          class="text-white"
                        >
                          <b-badge
                            pill
                            class="text-capitalize px-1 py-50 d-inline-flex align-items-center justify-content-center"
                            :style="{ 'background-color': commitment.commitment_set.status_set.color }"
                          >
                            {{ commitment.commitment_set.status_set.name | truncate(25, '...') }}
                          </b-badge>
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-table
                    ref="refItemTable"
                    class="position-relative"
                    thead-class="itemTableHeader"
                    primary-key="id"
                    responsive
                    show-empty
                    striped
                    empty-text="Não há itens cadastrados."
                    :items="commitment.items"
                    :fields="tableColumns"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                    :busy="isLoading"
                  >
                    <template #table-busy>
                      <div class="text-center my-3">
                        <b-spinner
                          variant="info"
                          class="align-middle mb-1"
                        />
                        <br>
                        <strong class="pt-2">Carregando...</strong>
                      </div>
                    </template>
                    <template #cell(field_lote)="field">
                      <b-media vertical-align="center">
                        <span
                          v-if="field.item.item_set.contract_item.parent_set"
                          class="font-weight-bold d-block text-nowrap"
                        >
                          {{ field.item.item_set.contract_item.parent_set.name }}
                        </span>
                        <span
                          v-else
                          class="font-weight-bold d-block text-nowrap"
                        >
                          -
                        </span>
                      </b-media>
                    </template>
                    <template #cell(field_type)="field">
                      <b-media vertical-align="center">
                        <span
                          class="font-weight-bold d-block text-nowrap"
                        >
                          <svg-icon
                            v-if="field.item.item_set.contract_item.type === 'unit'"
                            class="d-inline-block biddingIconType ml-50 inconType"
                            type="mdi"
                            :path="mdiAlphaUBoxOutline"
                          />
                          <svg-icon
                            v-if="field.item.item_set.contract_item.type === 'compound'"
                            class="d-inline-block biddingIconType ml-50 inconType"
                            type="mdi"
                            :path="mdiAlphaCBox"
                          />
                        </span>
                      </b-media>
                    </template>
                    <template #cell(field_name)="field">
                      <b-media vertical-align="center">
                        <span
                          class="font-weight-bold d-block text-nowrap"
                        >
                          {{ field.item.item_set.contract_item.name }}
                        </span>
                      </b-media>
                    </template>
                    <template #cell(field_qtd)="field">
                      <b-media vertical-align="center">
                        <span
                          class="font-weight-bold d-block text-nowrap"
                        >
                          {{ field.item.quantity }}
                        </span>
                      </b-media>
                    </template>
                    <template #cell(field_cost)="field">
                      <b-media vertical-align="center">
                        <span
                          class="font-weight-bold d-block text-nowrap"
                        >
                          R$ {{ field.item.item_set.contract_item.price | formatCurrency() }}
                        </span>
                      </b-media>
                    </template>
                    <template #cell(field_total_cost)="field">
                      <b-media vertical-align="center">
                        <span
                          class="font-weight-bold d-block text-nowrap"
                        >
                          R$ {{ (field.item.item_set.contract_item.price * field.item.quantity) | formatCurrency() }}
                        </span>
                      </b-media>
                    </template>
                    <template #cell(field_actions)="field">
                      <b-row>
                        <b-col
                          cols="12"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <b-button
                            class="btn-icon rounded itemValueBtn"
                            variant="primary"
                            size="sm"
                            :disabled="field.item.quantity === 0"
                            @click="removeItem(field.item)"
                          >
                            <feather-icon
                              icon="MinusIcon"
                              size="16"
                            />
                          </b-button>
                          <b-form-input
                            v-model="itemList[field.item.item].quantity"
                            class="itemValueInput mx-50"
                            type="number"
                            :disabled="field.item.quantity === 0"
                            @input="watchQuantity(field.item)"
                          />
                          <b-button
                            class="btn-icon rounded itemValueBtn"
                            variant="primary"
                            size="sm"
                            :disabled="field.item.quantity === 0"
                            @click="addItem(field.item)"
                          >
                            <feather-icon
                              icon="PlusIcon"
                              size="16"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
          </b-form>
        </b-modal>
      </validation-observer>
    </div>
    <b-row v-if="!readonly">
      <b-col
        id="btnAddDeliveryItemParent"
        class="d-flex justify-content-end mr-2"
      >
        <b-button
          id="btnAddDeliveryItem"
          class="btn-icon rounded-circle mb-1 mr-2"
          variant="primary"
          size="md"
          title="Adicionar Item"
          @click="openModal()"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BBadge,
  BTable,
  BForm,
  BMedia,
  BButton,
  VBTooltip,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiTruckAlertOutline,
  mdiAlphaUBoxOutline,
  mdiAlphaCBox,
  mdiPlus,
  mdiLaunch,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import formValidation from '@core/comp-functions/forms/form-validation'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import formatCurrency from '@/components/utils/filters/formatCurrency'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BTable,
    BForm,
    BMedia,
    BButton,
    ValidationObserver,
    SvgIcon,
    SpinLoader,
    BSpinner,
    BFormInput,
  },
  filters: {
    formatCurrency,
    truncate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    deliveryId: {
      type: [Number, String],
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    deliveryItems: {
      type: [Array],
      required: true,
    },
  },
  data() {
    const isLoading = true

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const sortBy = 'name'
    const isSortDirDesc = false
    const tableColumns = [
      {
        key: 'field_lote',
        label: 'lote',
        thStyle: { width: '10%' },
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'field_type',
        label: 'tipo',
        thStyle: { width: '5%' },
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'field_name',
        label: 'Nome do produto',
        thStyle: { width: '25%' },
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'field_qtd',
        label: 'QTD Disponível',
        thStyle: { width: '15%' },
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'field_cost',
        label: 'Valor Unitário',
        thStyle: { width: '15%' },
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'field_total_cost',
        label: 'Valor Total',
        thStyle: { width: '15%' },
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'field_actions',
        label: 'QTD Selecionada',
        thStyle: { width: '15%' },
        thClass: 'text-center',
        tdClass: 'text-center',
      },
    ]

    const options = {
      number: {
        numeral: true,
        numeralDecimalScale: 0,
        numeralDecimalMark: '',
        delimiter: '',
        numeralPositiveOnly: true,
      },
    }

    const itemsDelivery = []

    const itemList = []

    return {
      refFormObserver,
      getValidationState,
      isLoading,
      sortBy,
      isSortDirDesc,
      itemsDelivery,
      tableColumns,
      options,
      itemList,
    }
  },
  computed: {
    data: {
      get() {
        return this.itemDeliveryValue
      },
      set(value) {
        this.$emit('itemDeliveryValueChange', value)
      },
    },
    emptyItemsToDelivery() {
      return this.itemsDelivery.length === 0
    },
  },
  methods: {
    fetchItemsList() {
      this.isLoading = true

      syslic
        .order
        .delivery
        .item
        .fetchItemsToDelivery(this.deliveryId)
        .then(response => {
          this.itemsDelivery = response.data.items_to_delivery

          this.itemsDelivery.forEach(commitment => {
            commitment.items.forEach(element => {
              const item = {
                delivery: this.deliveryId,
                item: element.item,
                quantity: 0,
              }

              this.itemList[element.item] = item
            })
          })

          this.refreshTables()
          this.isLoading = false
        })
        .catch(err => {
          if (err.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.isLoading = false
        })
    },
    openModal() {
      this.itemsDelivery = []

      this.itemList = []
      this.fetchItemsList()
      this.$refs.modalItemDelivery.show()
    },
    save() {
      const promiseList = []
      let isEmptyAdd = true
      let isEmptyUp = true

      const itemAdd = []
      const itemUpdate = JSON.parse(JSON.stringify(this.deliveryItems))

      Object.keys(this.itemList).forEach(keyAdd => {
        let isAdd = true
        Object.keys(itemUpdate).forEach(key => {
          if (itemUpdate[key].item === this.itemList[keyAdd].item) {
            itemUpdate[key].quantity += Number(this.itemList[keyAdd].quantity)
            isAdd = false
          }
        })

        if (isAdd) {
          itemAdd.push(this.itemList[keyAdd])
        }
      })

      Object.keys(itemAdd).forEach(key => {
        if (itemAdd[key].quantity > 0) {
          const result = syslic
            .order
            .delivery
            .item
            .addDeliveryItem(itemAdd[key])

          promiseList.push(result)

          isEmptyAdd = false
        }
      })

      Object.keys(itemUpdate).forEach(key => {
        if (itemUpdate[key].quantity > 0) {
          const result = syslic
            .order
            .delivery
            .item
            .updateDeliveryItem(itemUpdate[key].id, itemUpdate[key])

          promiseList.push(result)

          isEmptyUp = false
        }
      })
      if (isEmptyAdd && isEmptyUp) {
        this.$swal({
          title: 'Atenção!',
          text: 'Selecione pelo menos um item para adicionar a entrega.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 10000,
        })
      } else {
        Promise
          .all(promiseList)
          .then(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Itens adicionados a entrega com sucesso.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$refs.modalItemDelivery.hide()
            this.$emit('update')
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao adicionar itens a entrega.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.$refs.modalItemDelivery.hide()
          })
      }
    },
    watchQuantity(obj) {
      const qtd = this.itemList[obj.item].quantity

      if (qtd) {
        if (qtd > obj.quantity) {
          this.itemList[obj.item].quantity = Number(obj.quantity)
        }

        if (qtd.toString().length > obj.quantity.toString().length) {
          this.itemList[obj.item].quantity = 0
        }

        if (qtd < 0) {
          this.itemList[obj.item].quantity = 0
        }
      } else {
        this.itemList[obj.item].quantity = 0
      }

      this.refreshTables()
    },
    addItem(obj) {
      const qtd = Number(this.itemList[obj.item].quantity)

      if (qtd < obj.quantity) {
        this.itemList[obj.item].quantity = qtd + 1
      }

      this.refreshTables()
    },
    removeItem(obj) {
      const qtd = Number(this.itemList[obj.item].quantity)

      if (qtd > 0) {
        this.itemList[obj.item].quantity = qtd - 1
      }

      this.refreshTables()
    },
    refreshTables() {
      this.$refs.refItemTable.forEach(table => {
        table.refresh()
      })
    },
    goToCommitment(commitmentId) {
      const routeData = router.resolve({
        name: 'order-commitment-detail',
        params: {
          id: this.orderId,
          idcommitment: commitmentId,
        },
      })
      window.open(routeData.href, '_blank')
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTruckAlertOutline,
      mdiAlphaUBoxOutline,
      mdiAlphaCBox,
      mdiPlus,
      mdiLaunch,
      moment,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#idModalItemDelivery {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .modal-body{
    padding: 0 !important;
  }

  .modal-header .modal-title{
    font-size: 1em;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .modal-footer {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: end !important;
    background-color: #fff !important;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;

    .btn{
      min-width: 107px;
    }
  }

  .itemTableHeader th {
    color: $primary;
    background-color: #E3EDF3;
  }

  .inconType {
    color: $primary;
  }

  [dir] .modal-footer {
    border: 0px !important;
    background-color: #fff !important;
  }
}

#btnAddDeliveryItemParent {
  position: relative;
  margin-top: 4rem;
}

#btnAddDeliveryItem {
  position: absolute;
  right: 0;
  bottom: 0;
}

.commitmentList {
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 1.2rem;
    box-shadow: inset 0 0 .25rem rgb(0 0 0 / .3)
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #515766;
    border-radius: .45rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #434857;
  }
}

.commitmentDetail {
  background-color: $primary;
}

.commitmentLinkBtn {
  height: fit-content !important;
  width: fit-content !important;
  padding: 0 !important;
}

.flexStartWithGap {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  > p {
    margin: 0;
  }
}

.itemValueInput {
  max-width: 5rem;
  text-align: center;
}

.itemValueBtn {
  padding: .35rem !important;
  height: fit-content !important;
}

#modalItemSpinLoader {
  position: absolute;
  top: 25%;
}

</style>
