<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div
      v-show="isLoaded"
      id="deliveryEditPage"
    >
      <messager
        :module="'delivery'"
        :thread="delivery.id"
        :header="delivery.client_set.name"
        @ready="syncLoad.ready('messager')"
      />
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="11"
        >
          <b-row class="justify-content-center mb-1">
            <b-col
              cols="12"
            >
              <div>
                <b-row
                  v-if="!isInAudit && !isDeliveryDone"
                >
                  <b-col
                    class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                  >
                    <b-dropdown
                      id="actions"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      right
                      class="dropdown-icon-wrapper"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="SettingsIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>

                      <b-dropdown-item
                        v-if="isViewMode"
                        id="bnt-edit"
                        :disabled="!hasPermissionToUpdate"
                        @click="enableEdition()"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="!isDeliveryDone"
                        :disabled="!hasPermissionToDelete"
                        @click="showConfirmOrCancelDelete()"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiTrashCanOutline"
                        />
                        <span class="align-middle ml-50">Remover</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(checkDeliveryCotations)"
            >
              <b-row>
                <b-col>
                  <delivery-info
                    ref="deliveryInfo"
                    v-model="delivery"
                    :readonly="isViewMode"
                    :can-edit="hasPermissionToUpdate"
                    :is-invoicing="delivery.situation === 'invoicing' || delivery.situation === 'released'"
                    :is-done="delivery.situation === 'done'"
                    @ready="syncLoad.ready('delivery-info')"
                    @changeBasicSituation="updateDeliverySituation"
                    @changeAuditSituation="updateDeliveryAudit"
                    @checkChanges="hasChanges"
                  />
                  <b-card no-body>
                    <b-tabs
                      class="deliveryTab"
                      card
                    >
                      <b-tab
                        class="p-0"
                        :active="activeTab === dataTab.delivery"
                        @click="selectedTab(dataTab.delivery)"
                      >
                        <template #title>
                          <b-card-title>
                            <svg-icon
                              class="mr-50 deliveryIconTitle"
                              type="mdi"
                              :path="mdiTruckOutline"
                            />
                            <span class="d-inline">Logística</span>
                          </b-card-title>
                        </template>
                        <b-card-body
                          class="pt-0 pb-2 px-0"
                        >
                          <delivery-address
                            v-model="delivery"
                            :readonly="isReadonly"
                            @ready="syncLoad.ready('delivery-address')"
                          />
                          <delivery-item-list
                            ref="refDeliveryItemList"
                            :item-list="deliveryItems"
                            :delivery-id="deliveryId"
                            :order-id="orderId"
                            :loading-state="loadingItems"
                            :readonly="isReadonly"
                            @refresh="refreshItems"
                            @update-list="fetchItemsList"
                          />
                          <delivery-transport
                            ref="refDeliveryTransport"
                            :delivery-id="deliveryId"
                            :cotation-list="delivery.cotations_set"
                            :readonly="isViewMode || isDeliveryDone"
                            @ready="syncLoad.ready('delivery-transport')"
                          />
                          <delivery-data
                            v-model="delivery"
                            class="pt-1"
                            :readonly="isViewMode || isDeliveryDone"
                          />
                          <delivery-annotation
                            v-model="delivery"
                            class="pt-1"
                            :readonly="isReadonly"
                          />
                          <delivery-result
                            :items="deliveryItems"
                            :cotations="delivery.cotations_set"
                            :readonly="isReadonly"
                          />
                        </b-card-body>
                      </b-tab>
                      <b-tab
                        class="p-0"
                        :active="activeTab === dataTab.invoicing"
                        @click="selectedTab(dataTab.invoicing)"
                      >
                        <template #title>
                          <b-card-title>
                            <svg-icon
                              class="mr-50 deliveryIconTitle"
                              type="mdi"
                              :path="mdiCashRegister"
                            />
                            <span class="d-inline">Faturamento</span>
                          </b-card-title>
                        </template>
                        <b-card-body
                          class="pt-0 pb-2 px-0"
                        >
                          <invoicing-table
                            ref="refInvoicingTable"
                            :filter="filter"
                            :refresh="refresh"
                            @change-refresh="changeRefresh"
                            @ready="syncLoad.ready('delivery-invoicing')"
                          />
                        </b-card-body>
                      </b-tab>
                      <b-tab
                        class="p-0"
                        :active="activeTab === dataTab.attach"
                        @click="selectedTab(dataTab.attach)"
                      >
                        <template #title>
                          <b-card-title>
                            <svg-icon
                              class="mr-50 deliveryIconTitle"
                              type="mdi"
                              :path="mdiPaperclip"
                            />
                            <span class="d-inline">Anexos</span>
                          </b-card-title>
                        </template>
                        <b-card-body
                          class="p-0"
                        >
                          <delivery-attach
                            v-model="delivery.file_set"
                            :delivery-id="deliveryId"
                            :readonly="isViewMode"
                            @ready="syncLoad.ready('delivery-attach')"
                          />
                        </b-card-body>
                      </b-tab>
                      <b-tab
                        class="p-0"
                        :active="activeTab === dataTab.history"
                        no-body
                        @click="selectedTab(dataTab.history)"
                      >
                        <template #title>
                          <b-card-title>
                            <svg-icon
                              class="mr-50"
                              type="mdi"
                              size="21"
                              :path="mdiClipboardTextClockOutline"
                            />
                            <span class="d-inline">Histórico</span>
                          </b-card-title>
                        </template>
                        <b-row
                          v-if="activeTab === dataTab.history"
                        >
                          <b-col class="mt-3 pb-2">
                            <delivery-history
                              :delivery-id="deliveryId"
                            />
                          </b-col>
                        </b-row>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
              <b-row
                class="mb-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="idBtnDeliverySave"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="idBtnDeliveryCancel"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0"
                    @click="(fromCommitmentPage || fromOrderPage) ? backToCommitmentOrder() : backToDelivery()"
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row>
        <b-modal
          id="idModalConfirmAudit"
          ref="refModalConfirmAudit"
          no-close-on-backdrop
          no-close-on-esc
          centered
          cancel-variant="outline-primary"
          @ok="confirmAudit()"
          @cancel="cancelDeliveryAudit()"
          @hidden="cancelDeliveryAudit()"
        >

          <template #modal-title>
            Confirmar?
          </template>

          <template #modal-ok>
            Confirmar
          </template>
          <template #modal-cancel>
            Cancelar
          </template>

          <b-row
            align-h="center"
          >
            <b-col
              cols="12"
              md="12"
            >
              <div
                class="d-flex align-items-center justify-content-center"
              >
                <span
                  class="font-weight-bold"
                >
                  Frete acima do estimado, esta entrega entrará em auditoria, deseja prosseguir?
                </span>
              </div>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="idModalBlockedDelivery"
          ref="refModalBlockedDelivery"
          class="modalStyle"
          no-close-on-backdrop
          no-close-on-esc
          centered
          hide-footer
          hide-header-close
        >
          <b-row>
            <b-col
              cols="12"
              class="p-3"
            >
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModal"
              >
                <svg-icon
                  type="mdi"
                  size="70"
                  :path="mdiNoteSearchOutline"
                  :style="{ 'color': '#FF7E07' }"
                />
              </span>
              <span
                class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
              >
                Entrega bloqueada para auditoria
              </span>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="idModalUpdate"
          ref="modalImportingBid"
          no-close-on-backdrop
          no-close-on-esc
          hide-header
          hide-footer
          centered
        >
          <div class="pt-5 pb-4">
            <div class="d-flex justify-content-center">
              <b-spinner
                id="spinnerImport"
                variant="primary"
                type="grow"
              />
            </div>
            <h4
              class="d-flex justify-content-center mt-2 pl-50"
            >
              Atualizando...
            </h4>
          </div>
        </b-modal>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BForm,
  BButton,
  BTab,
  BTabs,
  BModal,
} from 'bootstrap-vue'

import {
  ValidationObserver,
} from 'vee-validate'

import {
  mdiPaperclip,
  mdiCash,
  mdiTruckOutline,
  mdiTrashCanOutline,
  mdiClipboardTextClockOutline,
  mdiNoteSearchOutline,
  mdiCashRegister,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import ChangeDetector from '@/components/utils/changeDetector'
import Messager from '@/components/messager/Messager.vue'
import DeliveryAddress from '@/views/order/delivery/DeliveryAddress.vue'
import DeliveryInfo from './DeliveryInfo.vue'
import DeliveryItemList from './DeliveryItemList.vue'
import DeliveryTransport from './DeliveryTransport.vue'
import DeliveryData from './DeliveryData.vue'
import DeliveryAnnotation from './DeliveryAnnotation.vue'
import DeliveryResult from './DeliveryResult.vue'
import DeliveryAttach from './DeliveryAttach.vue'
import InvoicingTable from '@/views/order/invoicing/InvoicingTable.vue'
import DeliveryHistory from './DeliveryHistory.vue'
import syslic from '@/syslic'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BButton,
    BTabs,
    BTab,
    BModal,
    ValidationObserver,
    SpinLoader,
    SvgIcon,
    DeliveryInfo,
    DeliveryItemList,
    DeliveryTransport,
    DeliveryData,
    DeliveryAnnotation,
    DeliveryResult,
    DeliveryAttach,
    InvoicingTable,
    DeliveryHistory,
    DeliveryAddress,
    Messager,
  },
  directives: {
    Ripple,
  },
  data() {
    let deliveryId = null
    let orderId = null
    let idCommitment = null
    let fromCommitmentPage = null
    let fromOrderPage = null

    let isViewMode = true

    const isLoaded = false

    const loadingItems = false

    const setAudit = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    deliveryId = router.currentRoute.params.idDelivery
    orderId = router.currentRoute.params.id
    idCommitment = router.currentRoute.params.idCommitment
    fromCommitmentPage = router.currentRoute.params.fromCommitmentPage
    fromOrderPage = router.currentRoute.params.fromOrderPage

    const auditPercentage = 0

    const delivery = {
      address: '',
      annotation: '',
      bidding: '',
      carrier: '',
      carrier_set: '',
      city: '',
      city_set: '',
      client_set: {
        name: '',
      },
      company: '',
      company_set: '',
      complement: '',
      country: '',
      country_set: '',
      date_delivery: null,
      file_set: [],
      freight_cost: '',
      id: '',
      items: [],
      neighborhood: '',
      number: '',
      state: '',
      state_set: '',
      status: '',
      status_set: '',
      zip_code: '',
      cotations_set: [],
      situation: 'idle',
    }

    const deliveryItems = []

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/view\/.*/)) {
      delivery.id = router.currentRoute.params.idDelivery
    }

    const dataTab = {
      delivery: 1,
      invoicing: 2,
      attach: 3,
      history: 4,
    }

    const activeTab = router.currentRoute.params.tab || dataTab.delivery

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('delivery')
    syncLoad.addTrigger('delivery-info')
    syncLoad.addTrigger('delivery-address')
    syncLoad.addTrigger('delivery-transport')
    syncLoad.addTrigger('delivery-attach')
    syncLoad.addTrigger('delivery-invoicing')
    syncLoad.addTrigger('messager')
    syncLoad.addTrigger('percentages')

    syncLoad.onReady(() => {
      this.isLoaded = true
      this.cancelEditIfAudit()
    })

    const changeDetector = new ChangeDetector()

    const filter = {
      page_size: 10,
      delivery: '',
    }

    const refresh = false

    return {
      isLoaded,
      loadingItems,
      setAudit,
      deliveryId,
      orderId,
      idCommitment,
      isViewMode,
      auditPercentage,
      delivery,
      deliveryItems,
      syncLoad,
      changeDetector,
      dataTab,
      activeTab,
      fromCommitmentPage,
      fromOrderPage,
      filter,
      refresh,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_delete
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.order.can_write
    },
    deliveryValue() {
      let value = 0
      this.deliveryItems.forEach(obj => {
        value += (parseFloat(obj.item_set.contract_item.price) * obj.quantity)
      })

      return value
    },
    isInAudit() {
      const value = this.delivery.situation === 'audit'

      return value
    },
    isDeliveryDone() {
      const value = this.delivery.situation === 'done'

      return value
    },
    estimatedFreight() {
      let value = 0
      this.deliveryItems.forEach(obj => {
        value += (parseFloat(obj.item_set.contract_item.price) * (parseFloat(obj.item_set.contract_item.freight) / 100) * obj.quantity)
      })
      return value
    },
    isReadonly() {
      return (
        this.isDeliveryDone
        || this.isViewMode
        || this.delivery.situation === 'invoicing'
        || this.delivery.situation === 'released'
      )
    },
  },
  created() {
    this.fetchData()
    this.changeDetector.bind(this, ['delivery'])
  },
  methods: {
    refreshItems(obj) {
      this.deliveryItems = JSON.parse(JSON.stringify(obj))
    },
    selectedTab(value) {
      this.activeTab = value
    },
    fetchData() {
      this.fetchDelivery()
      this.fetchDeliveryAuditPercentage()
      this.fetchItemsList()
    },
    fetchDelivery() {
      this.filter.delivery = this.deliveryId

      syslic
        .order
        .delivery
        .fetchDelivery(this.deliveryId)
        .then(response => {
          this.delivery = response.data
          this.syncLoad.ready('delivery')
        })
        .catch(() => {
          this.syncLoad.ready('delivery')
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar informações da entrega.',
              text: 'Não foi possível carregar informações da entrega, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.changeDetector.reset()

          if (this.delivery.situation === 'done') {
            this.disableEdition()
          }
        })
    },
    fetchDeliveryAuditPercentage() {
      syslic
        .order
        .audit
        .fetchPercentages()
        .then(response => {
          this.auditPercentage = response.data.delivery_percentage
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar informações de auditoria.',
              text:
                'Não foi possível carregar informações de auditoria, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.syncLoad.ready('percentages')
        })
    },
    fetchItemsList() {
      this.toggleItemListLoading()

      const params = {
        delivery: this.deliveryId,
      }

      syslic
        .order
        .delivery
        .item
        .fetchAllDeliveryItem(params)
        .then(response => {
          this.deliveryItems = response.data.results
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar itens da entrega, tente novamente ou entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.toggleItemListLoading()
        })
    },
    updateDelivery() {
      syslic
        .order
        .delivery
        .updateDelivery(this.deliveryId, this.delivery)
        .then(() => {
          const promise = this.$refs.refDeliveryTransport.save()
          return promise
        })
        .then(() => {
          const timeout = this.setAudit ? 4000 : 0

          if (this.setAudit) {
            this.showBlockedDeliveryModal()

            setTimeout(() => {
              this.disableEdition()
            }, timeout)
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Entrega atualizada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          this.fetchDelivery()
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar a Entrega.',
              text:
                'Não foi possível atualizar a entrega, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.changeDetector.reset()
        })
    },
    async updateDeliverySituation(val) {
      if (val !== 'invoicing') {
        let textContent = ''

        const textSituation = {
          released: 'concluir',
          idle: 'faturar',
        }

        if (val === 'done') {
          const haveInvoicing = await this.checkDeliveryHaveInvoicing(this.delivery.id)

          if (haveInvoicing) {
            textContent = 'Não é possível reabrir uma entrega com faturamento concluido!'
            this.atentionAlert(textContent)
            return
          }
        }

        if (
          !this.delivery.address
          || !this.delivery.neighborhood
          || !this.delivery.number
          || !this.delivery.zip_code
          || !this.delivery.city
          || !this.delivery.state
          || !this.delivery.country
        ) {
          textContent = `Não é possível ${textSituation[val]} uma entrega sem os campos de endereço preenchidos! (Endereço, Bairro, N°, CEP, Cidade, Estado e País)`
          this.atentionAlert(textContent)
          return
        }

        if (this.deliveryItems.length === 0) {
          textContent = `Não é possível ${textSituation[val]} uma entrega sem itens cadastrados!`
          this.atentionAlert(textContent)
          return
        }

        if (val !== 'idle' && this.delivery.situation !== 'done') {
          if (!this.delivery.cotations_set || !this.delivery.cotations_set.some(cotation => cotation.accepted)) {
            textContent = `Não é possível ${textSituation[val]} uma entrega sem uma cotação de frete contratada!`
            this.atentionAlert(textContent)
            return
          }

          if (this.delivery.invoicing_delivery_date === null) {
            textContent = 'Não é possível concluir uma entrega sem preencher o campo Data da Entrega!'
            this.atentionAlert(textContent)
            return
          }
        }
      }

      const nextSituationMap = {
        idle: 'invoicing',
        invoicing: 'idle',
        released: 'done',
        done: 'released',
      }
      const nextSituation = nextSituationMap[val]

      const data = {
        order: this.delivery.order,
        company: this.delivery.company,
        date_delivery: this.delivery.date_delivery,
        status: this.delivery.status,
        situation: nextSituation,
      }

      if (val === 'idle') {
        this.updateSituationInvoicing(data, val, nextSituation)
      } else {
        this.updateSituation(data, val, nextSituation)
      }
    },
    updateSituationInvoicing(data, val, nextSituation) {
      syslic
        .order
        .invoicing
        .addInvoicing({ delivery: this.deliveryId })
        .then(() => {
          this.$swal({
            title: '',
            text: 'Faturamento criado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 5000,
          })
          this.refresh = true
          return syslic.order.delivery.updateDelivery(this.deliveryId, data)
        })
        .then(() => {
          this.delivery.situation = nextSituation
          const res = {
            idle: 'enviada para faturamento',
            invoicing: 'reaberta',
            released: 'concluída',
            done: 'reaberta',
          }

          this.$swal({
            title: '',
            text: `Entrega ${res[val]} com sucesso!`,
            icon: 'success',
            showConfirmButton: false,
            timer: 5000,
          })
        })
        .catch(error => {
          let textError = 'Não foi possível criar o faturamento, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data[0].includes('There is no initial status for the invoicing')) {
              textError = 'Não existe status de faturamento inicial, adicione um status inicial para poder criar um faturamento.'
            }
          }
          this.$swal({
            title: '',
            text: textError,
            icon: 'warning',
            showConfirmButton: false,
            timer: 7000,
          })
        })
        .finally(() => {
          this.changeDetector.reset()
          this.fetchDelivery()
        })
    },
    updateSituation(data, val, nextSituation) {
      syslic
        .order
        .delivery
        .updateDelivery(this.deliveryId, data)
        .then(() => {
          this.delivery.situation = nextSituation
          const res = {
            idle: 'enviada para faturamento',
            invoicing: 'faturada',
            released: 'concluída',
            done: 'reaberta',
          }

          this.$swal({
            title: '',
            text: `Entrega ${res[val]} com sucesso!`,
            icon: 'success',
            showConfirmButton: false,
            timer: 5000,
          })
        })
        .catch(error => {
          if ('commitment' in error.data) {
            this.$swal({
              title: '',
              text: 'Não é possível reabrir uma entrega com itens de um empenho concluído!',
              icon: 'warning',
              showConfirmButton: false,
              timer: 8000,
            })
          } else if ('situation' in error.data) {
            this.$swal({
              title: '',
              text: 'Atualize a página para carregar a situação atual da entrega.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 8000,
            })
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao atualizar a situação da Entrega.',
                text: 'Não foi possível atualizar a situação da entrega, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          }
        })
        .finally(() => {
          this.changeDetector.reset()
          this.fetchDelivery()
        })
    },
    deleteDelivery() {
      syslic
        .order
        .delivery
        .deleteDelivery(this.deliveryId)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Entrega removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({
            name: 'order-detail',
            params: {
              id: this.orderId,
            },
          })
        })
        .catch(error => {
          let textError = 'Não foi possível remover a entrega do servidor, por favor entre em contato com o administrador do sistema.'
          let variantError = 'danger'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Esta entrega é referenciada em algum faturamento, por este motivo não será possível remove-la.'
              variantError = 'warning'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover entrega.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: variantError,
              timeout: false,
            },
          })
        })
    },
    setCarrierOnDelivery() {
      this.delivery.cotations_set.forEach(element => {
        if (element.accepted) {
          this.delivery.carrier = element.carrier
          this.delivery.carrier_set = element.carrier_set
        }
      })

      this.changeDetector.reset()
    },
    onSubmit() {
      this.setCarrierOnDelivery()
      this.updateDelivery()
    },
    disableEdition() {
      const commitment = router.currentRoute.params.idCommitment ? router.currentRoute.params.idCommitment : null
      const fromCommitment = router.currentRoute.params.fromCommitmentPage ? router.currentRoute.params.fromCommitmentPage : null
      const fromOrder = router.currentRoute.params.fromOrderPage ? router.currentRoute.params.fromOrderPage : null

      router.push({
        name: 'delivery-detail',
        params: {
          id: this.orderId,
          idDelivery: this.delivery.id,
          idCommitment: commitment,
          fromCommitmentPage: fromCommitment,
          fromOrderPage: fromOrder,
        },
      })
        .catch(() => {})
    },
    backToCommitmentOrder() {
      if (this.fromCommitmentPage) {
        router.push({
          name: 'order-commitment-detail',
          params: {
            id: this.orderId,
            idcommitment: this.idCommitment,
          },
        })
          .catch(() => {})
      } else if (this.fromOrderPage) {
        router.push({
          name: 'order-detail',
          params: {
            id: this.orderId,
          },
        })
          .catch(() => {})
      }
    },
    backToDelivery() {
      router.push({
        name: 'delivery',
      })
    },
    enableEdition() {
      const commitment = router.currentRoute.params.idCommitment ? router.currentRoute.params.idCommitment : null
      const fromCommitment = router.currentRoute.params.fromCommitmentPage ? router.currentRoute.params.fromCommitmentPage : null
      const fromOrder = router.currentRoute.params.fromOrderPage ? router.currentRoute.params.fromOrderPage : null

      router.push({
        name: 'delivery-edit',
        params: {
          id: this.orderId,
          idDelivery: this.delivery.id,
          tab: this.activeTab,
          idCommitment: commitment,
          fromCommitmentPage: fromCommitment,
          fromOrderPage: fromOrder,
        },
      })
    },
    showConfirmOrCancelDelete() {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover esta entrega.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteDelivery()
          }
        })
    },
    atentionAlert(textContent) {
      this.$swal({
        title: 'Atenção',
        text: textContent,
        icon: 'warning',
        showConfirmButton: false,
        timer: 8000,
      })
    },
    checkDeliveryHaveInvoicing(id) {
      return new Promise(resolve => {
        syslic
          .order
          .delivery
          .hasInvoicing(id)
          .then(response => {
            resolve(response.data.has_invoicing_done)
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao verificar existência de Faturamento com esta Entrega.',
                text:
                  'Não foi possível verificar se existe faturamento criado para essa entrega, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
          })
      })
    },
    showBlockedDeliveryModal() {
      this.$refs.refModalBlockedDelivery.show()
    },
    checkDeliveryCotations() {
      let aboveEstimated = false
      const maxFreight = Number(parseFloat(this.estimatedFreight + (this.estimatedFreight * (this.auditPercentage / 100))).toFixed(2))
      const lastAudit = Number(parseFloat(this.delivery.last_audit_value).toFixed(2))

      this.delivery.cotations_set.forEach(cotation => {
        const freight = Number(parseFloat(cotation.cost).toFixed(2))
        if (cotation.accepted && freight > maxFreight) {
          if (
            (lastAudit > 0 && freight !== lastAudit)
            || (lastAudit === 0 && freight > maxFreight)
          ) {
            aboveEstimated = true
          }
        }
      })

      if (aboveEstimated) {
        this.$refs.refModalConfirmAudit.show()
      } else {
        this.onSubmit()
      }
    },
    cancelDeliveryAudit() {
      this.$refs.refModalConfirmAudit.hide()
    },
    confirmAudit() {
      this.setAudit = true
      this.onSubmit()
    },
    cancelEditIfAudit() {
      if (router.currentRoute.path.match(/.*\/edit\/.*/)) {
        if (this.isInAudit) {
          this.disableEdition()
        }
      }
    },
    updateDeliveryAudit(situation) {
      const data = {
        situation,
      }

      syslic
        .order
        .delivery
        .situation
        .updateSituation(this.deliveryId, data)
        .then(response => {
          this.delivery.situation = response.data.situation
          const status = situation === 'declined' ? 'declinada' : 'liberada'
          this.$swal({
            title: '',
            text: `Auditoria ${status} com sucesso!`,
            icon: 'success',
            showConfirmButton: false,
            timer: 5000,
          })
        })
        .catch(() => {
          this.$swal({
            title: '',
            text: 'Falha ao atualizar situação da Entrega!',
            icon: 'error',
            showConfirmButton: false,
            timer: 5000,
          })
        })
        .finally(() => {
          this.changeDetector.reset()
          this.fetchDelivery()
        })
    },
    hasChanges() {
      if (this.changeDetector.hasChanges()) {
        return
      }
      this.$refs.deliveryInfo.changeDeliverySituation()
    },
    changeRefresh(val) {
      this.refresh = val
    },
    toggleItemListLoading() {
      this.loadingItems = !this.loadingItems
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiPaperclip,
      mdiCash,
      mdiTruckOutline,
      mdiTrashCanOutline,
      mdiClipboardTextClockOutline,
      mdiNoteSearchOutline,
      mdiCashRegister,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/core/colors/palette-variables.scss";

#deliveryEditPage {
  transform: none !important;
}

.deliveryIconTitle {
  width: 21px;
  height: 21px;
}

.deliveryTab .card-title {
  margin-bottom: 0;
}

.deliveryTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.deliveryTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.deliveryTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.deliveryTab .nav-link.active {
  background-color: #e2edf3;
}

.deliveryTab .nav-link.active h4 {
  color: $primary;
}

.deliveryTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.deliveryTab .card-body {
 padding-left: 1.0rem;
 padding-right: 1.0rem;
}

.deliveryTab .tab-content {
  margin-top: -1rem;
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

#idModalConfirmAudit {
  .modal-body {
    padding: 3.5rem 2.5rem 1rem 2.5rem;
    span {
      text-align: center;
      font-size: 1.35rem;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    padding-bottom: 2.5rem;

    border-top: none !important;

    button {
      min-width: 8rem;
    }
  }
}

#idModalUpdate {
  #idModalImportingBid___BV_modal_content_{
    border-radius:20px !important;
  }
}
</style>
